<template>
  <v-dialog
    v-model="show"
    width="600"
    overlay-opacity="0.9"
    :fullscreen="$vuetify.breakpoint.xs"
  >
    <v-card>
      <v-toolbar elevation="10">
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container fluid>
        <v-row dense>
          <v-col cols="4">
            <div class="text--primary">{{ $t("robotDetail.status") }}</div>
          </v-col>
          <v-col cols="8">
            <div class="text--primary">
              <span :class="cssClass"> {{ textEN }}</span>
            </div>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="4">
            <span class="subtitle-1">{{ $t("main.autoRenew") }}</span>
          </v-col>
          <v-col cols="8">
            <span v-if="colorRenew == 'red'" class="red--text">{{
              textRenew
            }}</span>
            <span v-if="colorRenew == 'green'" class="green--text">{{
              textRenew
            }}</span>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="4">
            <div class="text--primary">{{ $t("robotDetail.expDate") }}</div>
          </v-col>
          <v-col cols="8">
            <div class="text--primary">
              {{ expirationDate }}
            </div>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="4">
            <span class="subtitle-1" v-if="active">
              {{ $t("robotDetail.enable") }}
            </span>
            <span class="subtitle-1" v-else>
              {{ $t("robotDetail.disable") }}
            </span>
          </v-col>
          <v-col cols="8">
            <v-switch
              class="mt-0"
              v-model="active"
              label=""
              color="success"
              hide-details
              @change="changeStatus"
            ></v-switch>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="4">
            <span class="subtitle-1"> {{ $t("robotDetail.leverage") }} </span>
          </v-col>
          <v-col cols="8">
            <v-select
              color="info"
              outlined
              dense
              v-model="leverage"
              :items="leverageList"
              @change="changeLeverage"
            ></v-select>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col>
            <v-card class="d-flex justify-space-between pa-2" color="white">
              <div class="d-flex">
                <img
                  src="@/assets/binance.png"
                  class="ma-auto ml-1 mr-2"
                  width="25"
                  height="25"
                />
                <p
                  class="ma-auto ml-1 binance-title text-caption text-sm-subtitle-1"
                >
                  {{ $t("binance.connectBinanceTitle") }}
                </p>
              </div>
              <div>
                <v-btn
                  depressed
                  color="#EFF1FF"
                  @click="changebinanceKey"
                  class="d-none d-sm-flex"
                >
                  <span class="btn-change-key">{{
                    $t("binance.changeKey")
                  }}</span>
                </v-btn>

                <v-btn
                  icon
                  color="primary"
                  @click="changebinanceKey"
                  class="d-flex d-sm-none"
                >
                  <v-icon> mdi-cog </v-icon>
                </v-btn>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-btn
              class="mx-auto mt-1"
              block
              color="info"
              @click="setTradingHistoryDialogAction()"
            >
              {{ $t("tradingHistory.seeTradingHistory") }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-btn
              color="success"
              block
              @click.prevent="toRobotDetailRenew()"
              v-if="store == 'WEB'"
              >{{ $t("main.renew") }}</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <expiredBot
      :dialogStatus="expiredBotStatus"
      @close="expiredBotStatus = false"
    />
    <leverageDialog
      :dialogStatus.sync="leverageDialog"
      @close="leverageDialog = false"
      :portfolioBalance.sync="portfolioBalance"
    />
    <confirmDisableDialog
      :dialogStatus.sync="confirmDisableStatus"
      @close="cancelDisableStatus"
      @confirm="confirmDisable"
    />
    <binanceKeyDialog
      :dialogStatus.sync="binanceKeyStatus"
      @close="binanceKeyStatus = false"
      @changeBinanceKey="changeBinanceKey"
    />
    <MyTradingHistory
      :dialogStatus.sync="tradingHistoryDialogStatus"
      @close="tradingHistoryDialogStatus = false"
      :rentId.sync="id"
    />
    <input type="hidden" :value="store" />
  </v-dialog>
</template>

<script>
import { updateRentAPI } from "@/services/api/user";
import { getLeverageDetail } from "@/services/api/robot";
import expiredBot from "./expiredBot.vue";
import confirmDisableDialog from "./confirmDisable.vue";
import binanceKeyDialog from "./binanceKey.vue";
import leverageDialog from "./leverage.vue";
import { separator } from "@/utils/number";
import MyTradingHistory from "./MyTradingHistory.vue";
import { getLeverageList } from "@/utils/tradingValueSetHelper";

export default {
  name: "MySubscriptionsDetail",
  components: {
    // QrcodeStream,
    // QrcodeDropZone,
    expiredBot,
    confirmDisableDialog,
    leverageDialog,
    binanceKeyDialog,
    MyTradingHistory,
  },
  props: ["dialogStatus", "data"],
  data: () => {
    return {
      title: "",
      id: null,
      leverageList: [],
      active: true,
      leverage: 1,
      expiredBotStatus: false,
      confirmDisableStatus: false,
      leverageDetail: null,
      portfolioBalance: 0,
      leverageDialog: false,
      robotCode: null,
      binanceKeyStatus: false,
      expirationDate: "",
      cssClass: null,
      textEN: "",
      store: "",
      //
      textRenew: "",
      colorRenew: "",
      //
      tradingHistoryDialogStatus: false,
    };
  },
  methods: {
    async setData() {
      this.clearData();
      this.leverageList = getLeverageList();
      this.title = this.$changeLang(this.data.title);
      this.active = this.data.active;
      this.leverage = this.data.leverage;
      this.robotCode = this.data.robotCode;
      this.expirationDate = this.data.expireDate;
      this.cssClass = this.data.status.cssClass;
      this.textEN = this.$changeLang(this.data.status.text);
      this.store = this.data.store;
      this.id = this.data.id;
      this.colorRenew = this.data.autoRenew.color;
      this.textRenew = this.$changeLang(this.data.autoRenew.text);
      await this.getLeverage();
    },
    async cancelDisableStatus() {
      this.confirmDisableStatus = false;
      this.active = true;
    },
    async changeStatus(active) {
      if (!active) {
        this.confirmDisableStatus = true;
      } else {
        await this.updateRent({ id: this.id, active });
      }
    },
    async confirmDisable() {
      this.confirmDisableStatus = false;
      await this.updateRent({ id: this.id, active: false });
    },
    async updateRent(data) {
      let result = await updateRentAPI(data);
      if (result.message == "INCORRECT_KEY") {
        this.active = !this.active;
        this.expiredBotStatus = true;
      }

      this.$emit("getRentList");
    },
    async changeLeverage(leverage) {
      await this.updateRent({ id: this.id, leverage });
      await this.getPortfolioBalance(leverage);
      this.leverageDialog = true;
    },
    async changeBinanceKey(apiKey, secretKey) {
      await this.updateRent({ id: this.id, apiKey, secretKey });
      this.binanceKeyStatus = false;
    },
    async getLeverage() {
      this.leverageDetail = await getLeverageDetail(this.robotCode);
    },
    async getPortfolioBalance(leverage) {
      let balance = 0;
      if (this.leverageDetail) {
        balance =
          (this.leverageDetail.entryQuantity /
            ((this.leverageDetail.lotPercent /
              100 /
              this.leverageDetail.currentPrice) *
              leverage)) *
          1.3;
      }
      let totalBalance = balance.toFixed(2);
      this.portfolioBalance = await separator(totalBalance);
    },
    clearData() {
      this.title = "";
      this.isActive = "";
      this.leverageValue = "";
      this.id = null;
      this.robotCode = "";
    },
    changebinanceKey() {
      this.binanceKeyStatus = true;
    },
    //
    setTradingHistoryDialogAction() {
      this.tradingHistoryDialogStatus = true;
    },
    toRobotDetailRenew() {
      this.$router.push({
        path: "/robot",
        query: { code: this.robotCode, method: "renew" },
      });
    },
  },
  computed: {
    show: {
      get() {
        return this.dialogStatus;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  watch: {
    dialogStatus(visible) {
      if (visible) {
        this.setData();
      }
    },
    data(value) {
      if (value) {
        this.setData();
      }
    },
  },
};
</script>

<style scoped>
.binance-title {
  color: black;
  font-weight: bold;
}
.btn-change-key {
  color: #4e8afc;
}
.align-self-center {
  align-self: center;
}
</style>
<style lang="scss">
.v-list .v-list-item--active {
  color: inherit !important;
}
.v-list .v-list-item--active.primary--text {
  color: inherit !important;
}
</style>
