<template>
  <v-dialog v-model="show" width="400">
    <v-card height="98%" color="#172033">
      <v-toolbar>
        <v-toolbar-title>{{ $t("datepick.selectDate") }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-sheet class="pa-3">
        <v-row dense>
          <v-col cols="5">
            <center>
              <h5>{{ $t("datepick.startDate") }}</h5>
              <p style="height: 1rem">
                {{ rangeViewText.yearStart }}
                {{ rangeViewText.monthStart }}
                {{ rangeViewText.dayStart }}
              </p>
            </center>
          </v-col>
          <v-col cols="2">
            <center>
              <v-icon>mdi-arrow-right</v-icon>
            </center>
          </v-col>
          <v-col cols="5">
            <center>
              <h5>{{ $t("datepick.endDate") }}</h5>
              <p style="height: 1rem">
                {{ rangeViewText.yearEnd }}
                {{ rangeViewText.monthEnd }}
                {{ rangeViewText.dayEnd }}
              </p>
            </center>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <vc-date-picker
              v-model="range"
              :max-date="new Date()"
              is-range
              is-expanded
              is-dark
              color="green"
              @dayclick="changeDateText2"
              :locale="this.$t('datepick.localDatePicker')"
            />
          </v-col>
          <v-col cols="12">
            <v-btn block color="success" v-on:click="confirmActiion">
              {{ $t("datepick.confirm") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-sheet>
    </v-card>
  </v-dialog>
</template>

<script>
// import { getPaddingDateFormat } from "@/utils/customDate";
import Vue from "vue";
import VCalendar from "v-calendar";
import { monthEngName } from "@/utils/customDate";

Vue.use(VCalendar, {
  componentPrefix: "vc",
});

export default {
  name: "MyTradingHistoryDatePicker",
  components: {},
  props: {
    dialogStatus: {
      type: Boolean,
    },
    startDateTime: {
      type: Number,
    },
    endDateTime: {
      type: Number,
    },
  },
  // props: ["dialogStatus", "startDateTime", "endDateTime"],
  data: () => {
    return {
      range: {
        start: null,
        end: null,
      },
      rangeView: {
        start: null,
        end: null,
      },
      rangeRenderQueue: [],
      rangeViewText: {
        yearStart: "",
        monthStart: "",
        dayStart: "",
        yearEnd: "",
        monthEnd: "",
        dayEnd: "",
      },
    };
  },
  computed: {
    show: {
      get() {
        if (this.dialogStatus) {
          this.setDate();
        }
        return this.dialogStatus;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    // this function for on Day Click
    changeDateText(day) {
      let _conv_date = new Date(day.id);
      if (this.rangeView.start === null) {
        // start is nulll
        this.rangeView.start = _conv_date;
        this.rangeViewText.yearStart = _conv_date.getFullYear();
        this.rangeViewText.monthStart = monthEngName[_conv_date.getMonth()];
        this.rangeViewText.dayStart = _conv_date.getDate();
      } else {
        if (this.rangeView.end === null) {
          if (this.rangeView.start <= _conv_date) {
            // normal flow (same day or less)
            this.rangeView.end = _conv_date;
            this.rangeViewText.yearEnd = _conv_date.getFullYear();
            this.rangeViewText.monthEnd = monthEngName[_conv_date.getMonth()];
            this.rangeViewText.dayEnd = _conv_date.getDate();
          } else {
            // swap date start to end , new to start
            this.rangeView.end = this.rangeView.start;
            this.rangeViewText.yearEnd = this.rangeViewText.yearStart;
            this.rangeViewText.monthEnd = this.rangeViewText.monthStart;
            this.rangeViewText.dayEnd = this.rangeViewText.dayStart;

            // start to current click
            this.rangeView.start = _conv_date;
            this.rangeViewText.yearStart = _conv_date.getFullYear();
            this.rangeViewText.monthStart = monthEngName[_conv_date.getMonth()];
            this.rangeViewText.dayStart = _conv_date.getDate();
          }
        } else {
          // clear end set, set start (new chick)
          this.rangeView.end = null;
          this.rangeViewText.yearEnd = "";
          this.rangeViewText.monthEnd = "";
          this.rangeViewText.dayEnd = "";
          //
          this.rangeView.start = _conv_date;
          this.rangeViewText.yearStart = _conv_date.getFullYear();
          this.rangeViewText.monthStart = monthEngName[_conv_date.getMonth()];
          this.rangeViewText.dayStart = _conv_date.getDate();
        }
      }
    },
    changeDateText2(day) {
      let _conv_date = new Date(day.id);
      if (this.rangeRenderQueue.length == 2) {
        this.rangeRenderQueue = [];
      }

      if (this.rangeRenderQueue.length == 0) {
        this.rangeRenderQueue.push(_conv_date);
      } else {
        this.rangeRenderQueue.push(_conv_date);
        this.rangeRenderQueue = this.rangeRenderQueue.sort(function compare(
          a,
          b
        ) {
          return a - b;
        });
      }
      this.rangeViewText.yearStart = this.rangeRenderQueue[0].getFullYear();
      this.rangeViewText.monthStart =
        monthEngName[this.rangeRenderQueue[0].getMonth()];
      this.rangeViewText.dayStart = this.rangeRenderQueue[0].getDate();
      if (this.rangeRenderQueue.length == 2) {
        this.rangeViewText.yearEnd = this.rangeRenderQueue[1].getFullYear();
        this.rangeViewText.monthEnd =
          monthEngName[this.rangeRenderQueue[1].getMonth()];
        this.rangeViewText.dayEnd = this.rangeRenderQueue[1].getDate();
      }
    },
    setDate() {
      this.range = {
        start: null,
        end: null,
      };
      this.rangeRenderQueue = [];
      this.rangeView.start = null;
      this.rangeView.end = null;
      //
      this.rangeViewText.yearEnd = "";
      this.rangeViewText.monthEnd = "";
      this.rangeViewText.dayEnd = "";
      //
      this.rangeViewText.yearStart = "";
      this.rangeViewText.monthStart = "";
      this.rangeViewText.dayStart = "";
    },
    confirmActiion() {
      this.$emit("getDateFromDatePicker", this.rangeRenderQueue)
      this.$emit("close");
    },
  },
  mounted() {},
};
</script>

<style scoped>
/* .date-picker /deep/ input {
  display: block !important;
  width: 100% !important;
  color: #495057 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  padding: 0.25rem 0.5rem !important;
  font-size: 0.875rem !important;
  line-height: 1.5 !important;
  border-radius: 0.2rem !important;
} */
.vc-container {
  border-radius: 0rem !important;
  border: 0px !important;
}
</style>
